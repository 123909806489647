import i18n from 'src/i18n'
import { Relationships } from 'src/service-design/shared/document/relationships'
import {
  DocumentSpec,
  DocumentData,
} from 'src/service-design/shared/document/types'
import {
  validateSchema,
  SchemaError,
} from 'src/service-design/shared/validation'

const getDocumentSpec = <T extends string>(
  type: T,
  documentSpec: DocumentSpec<T>,
): DocumentSpec<T> | null => {
  let spec = documentSpec
  while (spec) {
    if (spec.kind === type) {
      return spec
    }
    spec = spec.parent
  }
  return null
}

const validateUniquenessConstraints = (
  data: DocumentData,
  relationships: Relationships<{ [collection: string]: { id: string } }>, // shame a bit hard to type
) =>
  relationships.collections.reduce<
    { id: string; type: string; message: string }[]
  >((acc, collection) => {
    const errors = relationships.validateConstraint(
      collection,
      // @ts-ignore collection is a string not an index into data
      data[collection],
    )
    acc.push(
      ...errors.map(({ row, constraint }, i: number) => {
        const columns = constraint.map(c => `${c}=${row[c]}`).join(', ')
        return {
          id: `${row.id}-${i}`,
          type: 'Failed Uniqueness Checks',
          message: `Row ${row.id} clashes on the uniqueness of columns ${columns} in ${collection}`,
        }
      }),
    )
    return acc
  }, [])

export const validateAll = (
  revisions: { data: DocumentData; meta?: { type: string }; id?: string }[],
  documentSpec: DocumentSpec<string>,
) => {
  const errors = []
  for (const rev of revisions) {
    const spec = rev.meta && getDocumentSpec(rev.meta.type, documentSpec)
    if (spec) {
      const revErrors = validateSchema(rev.data, spec.validate)
      errors.push(...revErrors)

      const uniquenessErrors = validateUniquenessConstraints(
        rev.data,
        spec.relationships,
      )
      errors.push(...uniquenessErrors)
    } else if (!rev.meta) {
      errors.push(
        new SchemaError(
          i18n.t('Revision {{rev.id}} invalid.', { rev }),
          i18n.t("Revision {{rev.id}} missing field 'meta'.", { rev }),
        ),
      )
    } else {
      const msg = i18n.t('Schema "{{rev.meta.type}}" not found.', { rev })
      errors.push(
        new SchemaError(
          msg,
          i18n.t(
            '{{msg}} The document you are opening may be of the wrong type',
            { msg },
          ),
        ),
      )
    }
  }
  return errors
}
